<template>
  <span>
    <vue-tooltip
      :displayText="withEndingDot ? `${getHumanizeDate}.` : getHumanizeDate"
      :text="getFullDate"
    />
  </span>
</template>

<script>
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
import VueTooltip from "@/view/components/Common/vueTooltip";

export default {
  components: { VueTooltip },
  props: {
    date: {
      type: [String, Number],
      default: null,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    withEndingDot: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getHumanizeDate() {
      return this.isExpired
        ? dayjs(this.date * 1000)
            .add(1, "day")
            .fromNow()
        : dayjs(this.date * 1000).fromNow();
    },
    getFullDate() {
      return this.isExpired
        ? dayjs(this.date * 1000)
            .add(1, "day")
            .format("MMM DD, YYYY hh:mm a")
        : dayjs(this.date * 1000).format("MMM DD, YYYY hh:mm a");
    },
  },
  methods: {},
};
</script>

<style scoped></style>
